@tailwind base;
@tailwind components;
@tailwind utilities;

/* Base styles*/
@layer base {
  body {
    font-family: theme('fontFamily.sans'); 
    background-color: theme('colors.tertiary.50');
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
  }
  h1 {
    font-family: theme('fontFamily.serif'); 
    font-size: min(15.00vw, 100px);
    letter-spacing: -0.03em;
    line-height: 0.95;
    font-weight: 400;
  }
  h2 {
    font-family: theme('fontFamily.serif'); 
    font-size: min(10.00vw, 64px);
    letter-spacing: -0.03em;
    line-height: 0.95;
    font-weight: 400;
  }
  /* Other base styles */
}
s{
  text-decoration: none;
    position: relative;
}

s::before{
    content: '';
    width: 100%;
    position: absolute;
    right: 0;
    top: calc( 50% + 8px );
    border-bottom: 6px solid rgba(0, 0, 0, 1);
}
/* Scale up hit targets on high resolution mobile devices. */
@media (min-resolution: 200dpi) {
  html {
    font-size: 18px;
  }

  .text-sm {
    /* ensure minimum font size of 16px */
    font-size: 0.9rem;
  }
}
/* 
body {
  @apply bg-white dark:bg-zinc-900;
  color-scheme: dark light;
} */
